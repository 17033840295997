<template>
  <el-dialog :title="CategoryData.id ? '编辑分类' : '添加分类'" :visible.sync="openDialog" width="500px"
    :show-close="true" class="dialog-vertical" @close="cancel('CategoryName')">
    <el-form size="medium" :model="CategoryData" :rules="rules" ref="CategoryName" label-width="95px" @submit.native.prevent>
      <el-form-item label="分类名称：" prop="name">
        <el-input type="text" style="width: 75%" v-model="CategoryData.name" placeholder="请输入分类名称"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('CategoryName')" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveCategory } from "../api/banner-category";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Data: {
      type: Object,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      rules: {
        name: [{ validator: this.CategoryNamePass, trigger: "blur" }],
      },
      saveLoading: false,
    };
  },
  methods: {
    // 验证内容
    CategoryNamePass(rule, value, callback) {
      console.log(value);
      if (value === "") {
        callback(new Error("请输入分类名称"));
      } else {
        callback();
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          saveCategory(this.CategoryData)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.$refs[formName].resetFields();
              this.saveLoading = false;
              this.openDialog = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    CategoryData: {
      get() {
        return this.Data;
      },
      set(val) {
        this.$emit("update: updateData", val);
      },
    },
  },
};
</script>

<style>
</style>
